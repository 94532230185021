type DownloadFilename = { filename: string; mimeType?: string };
type DownloadBlob = DownloadFilename & { data: string | Blob; href?: never };
type DownloadFile = DownloadFilename & { href: string; data?: never };

export type DownloadProps = DownloadBlob | DownloadFile;
/**
 * This functions receives a filename and either a data or a link, not both
 * @param options
 * @param {string} options.filename - The name of the file when saving to disk
 * @param {string} options.href - A link to the file to download
 * @param {string} options.data - A string that will be converted into a blob an downloaded
 * @param {string} options.mimeType - A string indicating the MIME type of the data contained in the Blob ( example: 'text/csv' )
 * List of MIME types: https://www.iana.org/assignments/media-types/media-types.xhtml
 */
export const downloadFile = ({
  data,
  href,
  filename,
  mimeType,
}: DownloadProps) => {
  let url = '';
  if (data) {
    if (data instanceof Blob) {
      url = window.URL.createObjectURL(data);
    } else {
      const blob = new Blob([data], { type: mimeType });
      url = window.URL.createObjectURL(blob);
    }
  }

  if (href) {
    url = href;
  }

  const a = document.createElement('a');

  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();

  if (data) {
    // Clean up ( release url )
    window.URL.revokeObjectURL(url);
  }
};
