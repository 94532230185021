import { Button } from '@latitude/button';
import { Separator } from '@latitude/separator';
import { Text } from '@latitude/text';
import cn from 'classnames';
import { actionVariants } from 'variants/action-variants';
import { separatorVariants } from 'variants/separator-variants';

import { useCopyToClipboard } from '@anchorage/hooks';

import { SafeTooltip } from './SafeTooltip';
import { Toast } from './Toast';
import { ToastAction } from './ToastAction';
import { ToastClose } from './ToastClose';
import { ToastDescription } from './ToastDescription';
import { ToastIcon } from './ToastIcon';
import { ToastTitle } from './ToastTitle';
import { useToasterContext } from './ToasterProvider';

export const CopyToolipText =
  'Double-click the text to copy it to your clipboard';

const CopyTimeout = 3000;

/**
 * Renders the content of the toaster component.
 * @returns JSX.Element
 */
export const ToasterContent = () => {
  const { toasts } = useToasterContext();

  const [copyToClipboard, copied] = useCopyToClipboard(CopyTimeout);

  const handleDoubleClick = (textToCopy: string) => {
    copyToClipboard(textToCopy);
  };

  return toasts.map(
    ({
      id,
      title,
      description,
      action,
      link,
      variant = 'info',
      allowCopyToClipboard,
      ...props
    }) => {
      const { href, children: linkText, altText: linkAltText } = link || {};
      const {
        altText: actionAltText,
        fill = 'outline',
        ...buttonProps
      } = action || {};

      const allowCopy = allowCopyToClipboard ?? variant === 'danger';

      const textContent = (
        <>
          {title && <ToastTitle>{title}</ToastTitle>}
          {description && <ToastDescription>{description}</ToastDescription>}
        </>
      );

      return (
        <Toast key={id} variant={variant} {...props}>
          {copied && (
            <span
              // this is a temporary style/component until we have a proper design for this
              // already discussed with design team and we will push it like this for now
              // so the user has a visual feedback that the text was copied
              style={{ background: 'rgba(0, 0, 0, 0.4)' }}
              data-testid="copied-badge"
              className="absolute rounded text-[small] m-1 p-1 left-0 top-0 fade-in-500 "
            >
              Copied
            </span>
          )}
          <ToastIcon variant={variant} />

          <div
            className="w-[calc(100%_-_80px)] overflow-hidden"
            data-testid="toast-text-content"
            onDoubleClick={() => {
              if (!allowCopy) {
                return;
              }

              handleDoubleClick(`${title}\n${description}`);
            }}
          >
            {allowCopy ? (
              <SafeTooltip text={CopyToolipText}>{textContent}</SafeTooltip>
            ) : (
              textContent
            )}
          </div>

          {linkAltText && (
            <ToastAction altText={linkAltText} asChild>
              <Text variant="link">
                <a href={href}>{linkText} </a>
              </Text>
            </ToastAction>
          )}

          {actionAltText && (
            <ToastAction altText={actionAltText} asChild>
              <div>
                <Button
                  variant="strong"
                  type={fill!}
                  className={cn(
                    actionVariants({ fill }),
                    '!border-current !text-current',
                  )}
                  size="tiny"
                  {...buttonProps}
                />
              </div>
            </ToastAction>
          )}

          <Separator
            orientation="vertical"
            className={cn(separatorVariants({ variant }), '!h-6')}
          />

          <ToastClose />
        </Toast>
      );
    },
  );
};
