/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ApolloError } from '@apollo/client';
import { GraphQLError, GraphQLFormattedError } from 'graphql';

import UIDeveloperError from './errors/UIDeveloperError/UIDeveloperError';
import WrappedError from './errors/WrappedError/WrappedError';

export enum SentryEnvironments {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export type { ApolloError, GraphQLError };

export type ErrorStack = {
  componentStack: string;
};

export type ErrorOptions = {
  neverThrowError?: boolean;
  orgID?: string;
  operationName?: string;
  operationVariables?: Record<string, any>;
};

export type ServerError = Error & {
  response: Response;
  result: any;
  statusCode: number;
};

// Thrown when a server's resonse is cannot be parsed
export type ServerParseError = Error & {
  response: Response;
  statusCode: number;
  bodyText: string;
};

// Error that can be thrown inside the Apollo lifecycle
// https://github.com/apollographql/apollo-link/blob/master/packages/apollo-link-error/src/index.ts#L13-L19
export type ApolloLinkError = {
  graphQLErrors?: GraphQLError[];
  networkError?: Error | ServerError | ServerParseError;
};

export type ClientError =
  | Error
  | WrappedError
  | GraphQLError
  | GraphQLFormattedError
  | ApolloError
  | UIDeveloperError;
