import type {
  ApolloError,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import { useEffect } from 'react';

type CustomApolloHook<T extends OperationVariables> = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseOptions: QueryHookOptions<any, T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => QueryResult<any, T>;

// Wrapper function for useQuery. It automatically stops polling on error
// so we don't have to specify it for every query.
export const usePollingQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  queryHook: CustomApolloHook<TVariables>,
  options: QueryHookOptions<TData, TVariables> = {},
): QueryResult<TData, TVariables> => {
  const { onError, pollInterval = 60000, ...remainingOptions } = options;
  const { refetch, startPolling, stopPolling, ...remainingData } = queryHook({
    pollInterval,
    ...remainingOptions,
    onError: (error: ApolloError) => {
      // onError may not exist, this check prevents the page from breaking
      if (onError) {
        onError(error);
      }
      stopPolling();
    },
  });

  const stopPollingFn = () => {
    stopPolling();
  };

  const startPollingFn = () => {
    void refetch();
    startPolling(pollInterval);
  };

  useEffect(() => {
    if (remainingOptions.skip) {
      return;
    }

    window.addEventListener('blur', stopPollingFn);
    window.addEventListener('focus', startPollingFn);

    return () => {
      window.removeEventListener('blur', stopPollingFn);
      window.removeEventListener('focus', startPollingFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingOptions.skip]);

  return {
    stopPolling,
    startPolling,
    refetch,
    ...remainingData,
  };
};
