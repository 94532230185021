import _get from 'lodash.get';

import { ApolloError, ClientError, ErrorOptions } from '../types';

export const isCypressEnv = () => {
  // Detect if we are running in a Cypress environment
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Boolean(window?.Cypress);
};

export const shouldThrowError = (
  _err: ClientError,
  opts?: ErrorOptions,
): boolean => {
  // Do not throw errors unless we are in a testing environment because we don't
  // want to trigger the error boundary.
  if (typeof window !== 'undefined' && !isCypressEnv()) {
    return false;
  }

  // Explicitly opt out of throwing errors for this error type
  if (opts && opts.neverThrowError) {
    return false;
  }

  // Throwing errors within the Cypress env lets us assert that we do NOT have
  // any existing errors for higher confidence in the e2e tests
  return true;
};

// Print the error to the console
/* eslint-disable no-console */
export const printError = (error: ClientError): void => {
  if (error) {
    console.group('Error reported:');
    console.error(error);
    console.groupEnd();
  }
};

// HTTP Status Codes we should ignore
export const STATUSES_TO_IGNORE = [401, 403, 404, 408, 500, 502, 503, 504];

// TODO @Luis improve error types.
// It will be necessary if we want to remove lodash dependency here
export const shouldSendErrorToSentry = (
  error: Error | string | null,
): boolean => {
  if (!error) {
    return true;
  }

  // Check if we have a status code somewhere in this error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const statusCode: number =
    // ServerParseError or SyntaxError parsing HTML
    _get(error, ['statusCode']) ||
    // networkError
    _get(error, ['networkError', 'statusCode']) ||
    // WrappedError with networkError
    _get(error, ['cause', 'networkError', 'statusCode']);

  // NOTE: There is one more case we haven't handled yet, when
  // graphQLErrors contains the results of failing HTTP calls.
  // See https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-error#error-categorization

  if (STATUSES_TO_IGNORE.includes(statusCode)) {
    // Do not send this error to Sentry
    return false;
  }

  return true;
};

// Strings or regexps that match errors to ignore
export const SENTRY_IGNORE_ERRORS = [
  // Intermittent connectivity issue from the fetch API. Not actionable.
  // Retries are in place on the clients, respectively.
  'Failed to fetch',
  // Safari error
  'The network connection was lost',
  // Benign error that means that ResizeObserver was not able to deliver all observations within a single animation frame.
  'ResizeObserver loop',
  // SDK_READY_TIMED_OUT
  'SDK_READY_TIMED_OUT',
  // User attributes a deposit and tries to add the address as a trusted source which is already there
  'Already a trusted source',
];

export const isAlreadyTrustedSourceError = (error: string) =>
  error.startsWith('Already a trusted source');

export const isUnauthorizedError = (error: ApolloError | undefined) => {
  if (!error) {
    return false;
  }

  const { networkError } = error;
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    return true;
  }

  return false;
};
