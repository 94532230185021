import { GraphQLFormattedError } from 'graphql';

import { ErrorOptions, GraphQLError } from '../../types';

// Create a unique message for each path/message combo.
// If this gets noisy we can look into removing the path and using fingerprints instead.
export const formatMessage = (
  { message, path }: GraphQLError | GraphQLFormattedError,
  opts?: ErrorOptions,
): string => {
  // Error Name
  const opNameStr = opts?.operationName
    ? `GQL operation ${opts.operationName} errored: `
    : '';
  const messageStr = message ? `"${message}"` : 'Unknown ClientGraphQLError';
  const pathStr = path?.join('.');

  return `${opNameStr}${messageStr} at resolver: ${pathStr || 'unknown'}`;
};
// ClientGraphQLError allows us to take an object that was originally
// a GraphQL error but was passed through Apollo middleware and is no
// longer an instanceof Error, and turn it into an instanceof Error
// with a custom `message` property for more useful error reports

// Flow allows classes to operate as both a value and a type
export default class ClientGraphQLError extends Error {
  cause: GraphQLError | GraphQLFormattedError;

  constructor(
    graphQLError: GraphQLError | GraphQLFormattedError,
    opts?: ErrorOptions,
  ) {
    // Create a unique message for each path/message combo
    super(formatMessage(graphQLError, opts));

    // This key is used to sort out GQL errors to a seperate slack channel
    // It should not be changed without updating the sentry alert policies
    const gqlErrorKey = '[GQL]';
    const name = opts?.operationName
      ? ` Error during ${opts.operationName}`
      : ' Unknown ClientGraphQLError';

    // Set the name of this error for display purposes
    this.name = gqlErrorKey + name;

    // Keep a reference to the original error that caused this
    this.cause = graphQLError;

    // captureStackTrace may not exist (IE)
    if (
      Object.prototype.hasOwnProperty.call(Error, 'captureStackTrace') &&
      !!Error.captureStackTrace
    ) {
      // Pass this.constructor to get rid of this constructor
      // in the stack trace
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(graphQLError.message).stack;
    }
  }
}
