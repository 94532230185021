import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';

import { UIDeveloperError, handleError } from '@anchorage/sentry';

import { DateOptions } from './types';

import getUTCDateMillis from './getUTCDateMillis';

/**
 * * TODO: Add explanation
 * @param date
 * @param opts
 */
export default function parseDate(
  date: string | number,
  opts?: DateOptions,
): Date {
  // Cast to a number. All of our dates are in unix nanos
  const timestampNanos = +date;
  // Cast to milliseconds so that we can use date fns
  const timestampMillis = Math.round(timestampNanos / 1000000);

  let millis = timestampMillis;
  // If we should format this date in UTC instead of local time, add/subtract
  // the appropriate offset
  if (opts && opts.formatInUTC) {
    millis = getUTCDateMillis(timestampMillis);
  }

  // Parse the date from the timestamp milliseconds format relative to now
  const parsedDate = parse(millis.toString(), 'T', new Date());
  if (!isValid(parsedDate)) {
    handleError(new UIDeveloperError(`Unsupported date format: ${date}`));
  }
  return parsedDate;
}
