import cn from 'classnames';

export const AddUserIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AddUser', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      {...restProps}
    >
      <title>{title}</title>

      <path d="M15.8125 16.4996L15.8125 13.7496H17.1875V16.4996H19.9375V17.8746H17.1875L17.1875 20.6246H15.8125L15.8125 17.8746H13.0625V16.4996H15.8125Z" />
      <path d="M7.5625 13.0621C5.66402 13.0621 4.125 14.6012 4.125 16.4996L4.125 17.8746C4.125 18.634 4.74061 19.2496 5.5 19.2496H11.0859V17.8746H5.5L5.5 16.4996C5.5 15.3605 6.42341 14.4371 7.5625 14.4371H13.75V13.0621L7.5625 13.0621Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2.74963C8.72182 2.74963 6.875 4.59646 6.875 6.87463C6.875 9.15281 8.72182 10.9996 11 10.9996C13.2782 10.9996 15.125 9.15281 15.125 6.87463C15.125 4.59646 13.2782 2.74963 11 2.74963ZM8.25 6.87463C8.25 5.35585 9.48122 4.12463 11 4.12463C12.5188 4.12463 13.75 5.35585 13.75 6.87463C13.75 8.39342 12.5188 9.62463 11 9.62463C9.48122 9.62463 8.25 8.39342 8.25 6.87463Z"
      />
    </svg>
  );
};
