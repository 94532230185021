import cn from 'classnames';

const CircleDoNotDisturbIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CircleDoNotDisturb', className, ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      className={cn('fill-current', className)}
      {...restProps}
    >
      <title>{title}</title>
      <path d="M3.66634 6.33434V7.66767H10.333V6.33434H3.66634ZM6.99967 0.334335C3.31634 0.334335 0.333008 3.31767 0.333008 7.001C0.333008 10.6843 3.31634 13.6677 6.99967 13.6677C10.683 13.6677 13.6663 10.6843 13.6663 7.001C13.6663 3.31767 10.683 0.334335 6.99967 0.334335ZM6.99967 12.3343C4.05967 12.3343 1.66634 9.941 1.66634 7.001C1.66634 4.061 4.05967 1.66767 6.99967 1.66767C9.93967 1.66767 12.333 4.061 12.333 7.001C12.333 9.941 9.93967 12.3343 6.99967 12.3343Z" />
    </svg>
  );
};

export { CircleDoNotDisturbIcon };
