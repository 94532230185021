import {
  CircleCheckFilledIcon,
  CircleInfoIcon,
  CircleWarningIcon,
  ReportIcon,
  TriangleWarningIcon,
} from '@latitude/icons';
import { Spinner } from '@latitude/spinner';
import { VariantProps } from 'class-variance-authority';
import { toastVariants } from 'variants/toast-variants';

/**
 * Renders an icon based on the provided variant.
 *
 * @param variant - The variant of the toast.
 * @returns The rendered icon component.
 */
export const ToastIcon = ({ variant }: VariantProps<typeof toastVariants>) => {
  let Icon: typeof CircleCheckFilledIcon;

  if (variant === 'loading') {
    return <Spinner size="medium" />;
  }

  switch (variant) {
    case 'success':
      Icon = CircleCheckFilledIcon;
      break;
    case 'warning':
      Icon = TriangleWarningIcon;
      break;
    case 'danger':
      Icon = CircleWarningIcon;
      break;
    case 'alert':
      Icon = ReportIcon;
      break;

    default:
      Icon = CircleInfoIcon;
      break;
  }
  return <Icon className="size-6 fill-current shrink-0" />;
};
