import cn from 'classnames';

export const MultiUserApprovalIcon = ({
  className,
  title = 'MultiUserApproval',
  ...restProps
}: SVGProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56 56"
      className={cn('fill-current', className)}
      {...restProps}
    >
      <title>{title}</title>
      <rect
        width="19.564"
        height="41.987"
        x="5.75"
        y="1.75"
        stroke="#fff"
        strokeWidth="1.5"
        rx="1.968"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.904 5.327L17.161 5.327"
      />
      <path
        stroke="#CE5932"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.115 23.083l2.718 2.718 6.455-6.455"
      />
      <rect
        width="19.564"
        height="41.987"
        x="32.93"
        y="11.263"
        stroke="#fff"
        strokeWidth="1.5"
        rx="1.968"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M41.084 14.84L44.34 14.84"
      />
      <path
        stroke="#CE5932"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M38.295 32.596l2.718 2.718 6.455-6.455"
      />
    </svg>
  );
};
