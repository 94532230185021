import crypto from 'crypto';

/**
 * Generates a universally unique identifier (UUID).
 *
 * This function attempts to use the `crypto.randomUUID` method if available,
 * which is a built-in method for generating UUIDs. If `crypto.randomUUID` is
 * not available, it falls back to generating a UUID using `crypto.randomBytes`.
 *
 * @returns {string} A string representing the generated UUID.
 */
export const uuid = () => {
  if (crypto && crypto.randomUUID) {
    return crypto.randomUUID();
  }

  if (window.crypto && !!window.crypto.randomUUID) {
    return window.crypto.randomUUID();
  }

  const random = crypto.randomBytes(16);
  random[6] = (random[6]! & 0x0f) | 0x40;
  random[8] = (random[8]! & 0x3f) | 0x80;

  return [
    random.toString('hex', 0, 4),
    random.toString('hex', 4, 6),
    random.toString('hex', 6, 8),
    random.toString('hex', 8, 10),
    random.toString('hex', 10, 16),
  ].join('-');
};
