import cn from 'classnames';

const DebugIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Debug', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
      {...restProps}
    >
      <title>{title}</title>

      <path d="M10 12v1H6v-2h4v1M10 8v1H6V7h4v1"></path>
      <path
        fillRule="evenodd"
        d="M12.388 3.912a5.8 5.8 0 0 0-.988-.83l1.668-1.668L11.654 0 9.466 2.188 9.4 2.15a4.63 4.63 0 0 0-2.84-.003L4.415 0l-.608.608a1 1 0 0 0-.208.208L3 1.414l.594.595a1 1 0 0 0 .081.09l.925.95q-.574.376-1.012.876A8 8 0 0 0 2.8 5H0v2h2.1a8 8 0 0 0-.087 1Q2 8.5 2 9H0v2h2q0 .5.013 1a8 8 0 0 0 .087 1H0v2h2.8a5.84 5.84 0 0 0 2.188 2.2Q6.375 18 8 18a5.93 5.93 0 0 0 3.012-.8A5.84 5.84 0 0 0 13.2 15H16v-2h-2.1q.075-.5.088-1T14 11h2V9h-2q0-.5-.012-1a8 8 0 0 0-.088-1H16V5h-2.8a6 6 0 0 0-.812-1.088M8 16q1.65 0 2.825-1.175T12 12V8q0-1.65-1.175-2.825T8 4 5.175 5.175 4 8v4q0 1.65 1.175 2.825T8 16"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export { DebugIcon };
