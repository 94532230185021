/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/browser';

import { ClientError, ErrorOptions } from '../../types';

import { ClientGraphQLError, FatalError, UIDeveloperError } from '..';
import {
  STATUSES_TO_IGNORE,
  isCypressEnv,
  printError,
  shouldThrowError,
} from '../helpers';

/**
 * setErrorScope adds custom key value pairs to the scope of the error. This
 * allows us to send extra data to Sentry for debugging
 * @param extraKeyValuePairs
 */
const setErrorScope = (extraKeyValuePairs: { [key: string]: any }): void => {
  Sentry.getCurrentScope().setExtras(extraKeyValuePairs);
};

/**
 * setLevel configures the scope of the reported exception to use a level other
 * than the default 'error' level. 'fatal' will page the on-call engineer.
 * @param level severity of the captured exception
 */
const setLevel = (level: Sentry.SeverityLevel): void => {
  Sentry.getCurrentScope().setLevel(level);
};

/**
 * handleError handles errors that are thrown in the application, it:
 * - Filters and categorizes out inactionable errors
 * - Adds custom key value pairs to the scope of the error
 * - Reports to Sentry
 * - Logs the error
 * @param error
 * @param opts
 */
export const handleError = (error: ClientError, opts?: ErrorOptions): void => {
  if (!error) {
    return;
  }

  let errorToReport: { [key: string]: any } = error;

  const isGQLError = !(error instanceof Error) && error.message && error.path;
  if (isGQLError) {
    // Typecast to a GraphQL Error
    const errorCode = error.extensions?.errorCode;
    if (STATUSES_TO_IGNORE.includes(errorCode as number) || errorCode === 400) {
      // We still want to capture these as low priority errors so FE oncall is aware of them when receiving reports
      setLevel('info');

      // Do not throw errors on these even if we are in a testing environment
      opts = { ...opts, neverThrowError: true };
    }

    setErrorScope({
      GQLOperationName: opts?.operationName,
      GQLVariables: opts?.operationVariables,
    });

    // Create an error object from the plain object given to us by Apollo
    errorToReport = new ClientGraphQLError(error, opts);
  }

  // Do not report ServerParseErrors, which are when the server sends back
  // HTML instead of valid JSON for reasons like the client is logged out, the
  // load balancer returns a 502, or the backend returns a 408. Don't report
  // these errors because they are not actionable
  if (errorToReport.name === 'ServerParseError') {
    return;
  }

  // These will call the on-call engineer
  if (errorToReport instanceof FatalError) {
    const fatalSeverityLevel: Sentry.SeverityLevel = 'fatal';
    setLevel(fatalSeverityLevel);
  }

  // Create warnings for UIDeveloperErrors. These should be known but not
  // page the on-call engineer
  if (errorToReport instanceof UIDeveloperError) {
    const warningSeverityLevel: Sentry.SeverityLevel = 'warning';
    setLevel(warningSeverityLevel);
  }

  setErrorScope(errorToReport);

  const extra: ErrorOptions = {};

  if (opts?.orgID) {
    extra.orgID = opts.orgID;
  }

  const hint = Object.keys(extra).length ? { extra } : undefined;

  // Do not report the error to Sentry if we are in a testing environment
  if (!isCypressEnv()) {
    if (errorToReport instanceof Error) {
      // Add each custom field to the error's scope so that the reports have
      // access to the custom fields
      if (hint) {
        Sentry.captureException(errorToReport, hint);
      } else {
        Sentry.captureException(errorToReport);
      }
    } else {
      // Call "captureEvent" instead of "captureException" so that Sentry does not
      // capture it as "Non-Error exception captured"
      Sentry.captureEvent(errorToReport);
    }
  }

  // Log the error
  printError(error);

  if (shouldThrowError(error, opts)) {
    throw error;
  }
};
