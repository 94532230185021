/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
export const base64File = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve((reader.result as string).split('base64,')[1]!);
    reader.onerror = (error) => reject(error);
  });
};
