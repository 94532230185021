import cn from 'classnames';

export const NFTIcon = ({
  className,
  title,
  ...restProps
}: SVGProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={cn('fill-current', className)}
      {...restProps}
    >
      <title>{title}</title>
      <path d="M9.279 14.8V9.199H8.275v3.792L5.882 9.199H4.799V14.8h1.004v-3.847L8.228 14.8h1.05zM14.464 10.123V9.2h-3.87V14.8h1.018v-2.252h2.544v-.924h-2.544v-1.5h2.852zM19.52 10.147v-.948h-4.338v.948h1.66V14.8h1.018v-4.653h1.66z" />
      <path
        fillRule="evenodd"
        d="M20.326 7.077l-7.927-4.576a.4.4 0 00-.4 0L4.073 7.077a.4.4 0 00-.2.347v9.152a.4.4 0 00.2.347l7.926 4.576a.4.4 0 00.4 0l7.927-4.576a.4.4 0 00.2-.347V7.424a.4.4 0 00-.2-.347zm-7.327-5.615a1.6 1.6 0 00-1.6 0L3.473 6.038a1.6 1.6 0 00-.8 1.386v9.152c0 .572.305 1.1.8 1.386l7.926 4.576a1.6 1.6 0 001.6 0l7.926-4.576a1.6 1.6 0 00.8-1.386V7.424a1.6 1.6 0 00-.8-1.386L13 1.462z"
        clipRule="evenodd"
      />
    </svg>
  );
};
