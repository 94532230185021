import { useRouter } from 'next/router';
import { useEffect } from 'react';

// Root page that should only be rendererd in local env
export const Redirect = ({ pathname }: { pathname: string }) => {
  const router = useRouter();
  useEffect(() => {
    router.push({ pathname, query: router.query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};
