import { NFTIcon } from '@latitude/icons';
import React from 'react';

// Types
import { FallbackIconProps } from './types';

export default function FallbackIcon({
  title,
  isFungible = true,
  ...restProps
}: FallbackIconProps): React.ReactElement<SVGSVGElement> {
  if (!isFungible) {
    return <NFTIcon title={title} {...restProps} />;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-5a5 5 0 100-10 5 5 0 000 10z"
        clipRule="evenodd"
      />
    </svg>
  );
}
