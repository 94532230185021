import _flattenDeep from 'lodash/flattenDeep';
import { useCallback, useEffect, useRef, useState } from 'react';

// Hooks
import { useDeepCompare } from '@anchorage/hooks';
import { uuid } from '@anchorage/utils';

import type { AnchorageComponentHook } from '../../types/hooks';

import type { CheckboxDependentProps, CheckboxProps } from './types';

const leafDependentsValues = (
  dependents: CheckboxDependentProps[],
): boolean[] => {
  const values = dependents.map((dependent) => {
    if (dependent.dependents) {
      return leafDependentsValues(dependent.dependents);
    }
    return !!dependent.checked;
  });

  return _flattenDeep(values);
};

type CheckboxHookProps = { dependents?: CheckboxDependentProps[] };

type CheckboxAdditionalProps = {
  dependent: CheckboxDependentProps;
  resetDefaultValue: () => void;
};

const useCheckbox: AnchorageComponentHook<
  CheckboxAdditionalProps,
  CheckboxProps,
  CheckboxHookProps
> = (defaultProps = {}) => {
  const {
    checked: defaultValue = false,
    dependents,
    ...restProps
  } = defaultProps;

  const [checked, setChecked] = useState<boolean>(
    dependents?.length ? false : defaultValue,
  );
  const [indeterminate, setIndeterminate] = useState(false);
  const [id] = useState(uuid());

  const ref = useRef<HTMLInputElement>(null);

  const resetDefaultValue = useCallback(
    () => {
      setChecked(dependents?.length ? false : defaultValue);
    },
    useDeepCompare([defaultValue, setChecked, dependents]),
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setChecked(event.target.checked);
      dependents?.forEach((dependent) => dependent.onChange?.(event));
    },
    [setChecked, dependents],
  );

  useEffect(() => {
    if (dependents?.length) {
      const leafValues = leafDependentsValues(dependents);
      const leafValuesEqualsToDefaultValue = leafValues.every(
        (value) => value === defaultValue,
      );

      if (leafValuesEqualsToDefaultValue || !defaultValue) {
        setChecked(defaultValue);
      } else {
        console.error(
          `Cannot have node with: ${defaultValue} and different leaf values: ${leafValues}`,
        );
      }
    }
  }, []);

  useEffect(() => {
    if (dependents?.length) {
      const leafValues = leafDependentsValues(dependents);
      const allChecked = leafValues.every(Boolean);
      const someChecked = leafValues.some(Boolean);

      setIndeterminate(!allChecked && someChecked);
      setChecked(allChecked);
    }
  }, [dependents]);

  return [
    {
      onChange,
      checked,
      id,
      ref,
      indeterminate,
      dependents,
      dependent: { checked, onChange, dependents },
      resetDefaultValue,
      ...restProps,
    },
    { onChange, checked, id, ref, indeterminate, ...restProps },
  ];
};

export default useCheckbox;
