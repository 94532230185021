import { screen, waitFor } from '@testing-library/react';
import { userEvent } from '@testing-library/user-event';

// This function hovers over a trigger element and tests that the tooltip is visible and contains the expected text. This process is needed for testing the new latitude tooltip.
export async function hoverAndTestTooltip(
  trigger: HTMLElement,
  expectedText?: string,
) {
  let tooltip;

  await userEvent.hover(trigger);

  await waitFor(() => {
    tooltip = screen.getByRole('tooltip');
    expect(tooltip).toBeVisible();
  });

  if (expectedText) {
    expect(tooltip).toHaveTextContent(expectedText);
  }
}
