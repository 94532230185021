/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState } from 'react';

export const DEFAULT_PAGE_SIZE = 10;

type Cursor = string | null;

export type PageInfo = {
  startCursor?: string;
  endCursor?: string;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
};

export type PaginationVariables = {
  first?: number;
  after?: Cursor;
  last?: number;
  before?: Cursor;
};

const DEFAULT_PAGINATION_VARIABLES: PaginationVariables = {
  first: DEFAULT_PAGE_SIZE,
};

export type TablePagination = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNext: () => void;
  onPrevious: () => void;
};

export type Params = {
  // follwing the spec defined in https://relay.dev/graphql/connections.htm
  specCompliantPrevNext?: boolean;
};

export type ParamsAndPaginationVariables = PaginationVariables & Params;

export type CursorBasedPagination = {
  // Variables to be passed down to the query
  paginationVariables: PaginationVariables;
  // Clear the pagination variables for the query and table
  resetPagination: () => void;
  // Variables to be passed down to the table
  tablePagination: TablePagination;
  // Function to update the pagination when new
  // pageInfo is returned from the query
  updatePagination: (pageInfo: PageInfo) => void;
};

type ResolvePrevNextArgs = {
  specCompliantPrevNext: boolean;
  isForward: boolean;
  pageInfo: PageInfo;
  resolveNext: boolean;
};

const resolvePrevNext = ({
  specCompliantPrevNext,
  isForward,
  pageInfo,
  resolveNext,
}: ResolvePrevNextArgs) => {
  if (!specCompliantPrevNext) {
    if (resolveNext) {
      return !!pageInfo.hasNextPage;
    }
    return !!pageInfo.hasPreviousPage;
  }
  if (resolveNext) {
    if (isForward) {
      return !!pageInfo.hasNextPage;
    }
    return !!pageInfo.hasPreviousPage;
  }
  if (isForward) {
    return !!pageInfo.hasPreviousPage;
  }
  return !!pageInfo.hasNextPage;
};

export const useCursorBasedPagination = (
  params: ParamsAndPaginationVariables = DEFAULT_PAGINATION_VARIABLES,
): CursorBasedPagination => {
  const {
    specCompliantPrevNext,
    ...initialPagination
  }: ParamsAndPaginationVariables = params;

  const [paginationVariables, setPaginationVariables] =
    useState<PaginationVariables>(initialPagination);

  const pageSize: number = useMemo(
    () =>
      initialPagination.first || initialPagination.last || DEFAULT_PAGE_SIZE,
    [initialPagination],
  );
  const [isForward, setForward] = useState<boolean>(true);

  const [pageInfo, setPageInfo] = useState<PageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const tablePaginationOnLoading = useCallback((oldState: PageInfo) => {
    // Prevent double clicking when we don't know if the next page exists.
    setPageInfo({
      ...oldState,
      hasNextPage: false,
      hasPreviousPage: false,
    });
  }, []);

  const onNext = useCallback(() => {
    setForward(true);
    const { endCursor } = pageInfo;
    tablePaginationOnLoading(pageInfo);
    setPaginationVariables({
      first: pageSize,
      after: endCursor,
    });
  }, [pageInfo, pageSize]);

  const onPrevious = useCallback(() => {
    const { startCursor } = pageInfo;
    setForward(false);
    tablePaginationOnLoading(pageInfo);
    setPaginationVariables({
      last: pageSize,
      before: startCursor,
    });
  }, [pageInfo, pageSize]);

  const updatePagination = useCallback((newPageInfo: PageInfo) => {
    setPageInfo(newPageInfo);
  }, []);

  const resetPagination = useCallback(() => {
    tablePaginationOnLoading(pageInfo);
    setPaginationVariables({ first: pageSize });
  }, [pageSize]);

  const tablePagination = useMemo(
    () => ({
      hasNextPage: resolvePrevNext({
        specCompliantPrevNext: !!specCompliantPrevNext,
        isForward,
        pageInfo,
        resolveNext: true,
      }),
      hasPreviousPage: resolvePrevNext({
        specCompliantPrevNext: !!specCompliantPrevNext,
        isForward,
        pageInfo,
        resolveNext: false,
      }),
      onNext,
      onPrevious,
    }),
    [isForward, pageInfo, specCompliantPrevNext],
  );

  const returnData = useMemo(
    () => ({
      paginationVariables,
      resetPagination,
      tablePagination,
      updatePagination,
    }),
    [paginationVariables, pageSize, tablePagination],
  );

  return returnData;
};
