import queryString from 'query-string';
import type { StringifiableRecord } from 'query-string';

export type PathOptions = {
  variables?: { [key: string]: string | undefined };
  query?: StringifiableRecord;
};

function buildPath(path = '', { variables = {}, query = {} }: PathOptions) {
  let updatedPath = path;
  Object.keys(variables).forEach((key) => {
    const value = encodeURIComponent(variables[key] ?? '');
    updatedPath = updatedPath.replace(`:${key}`, value);
  });

  const stringifiedQuery = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });

  if (stringifiedQuery) {
    updatedPath = `${updatedPath}?${stringifiedQuery}`;
  }

  return encodeURI(updatedPath);
}

export default buildPath;
