import _omit from 'lodash/omit';
import React from 'react';
import SVG, { Props as InlineSVGProps } from 'react-inlinesvg';

import type { Props } from './types';

import Loader from '../Loader';
import FallbackIcon from './FallbackIcon';

const ASSET_TYPE_INFO_FIELDS = [
  'name',
  'value',
  'displayValue',
  'abbreviation',
  'assetTypeID',
  'parentAssetTypeID',
  'parentAssetTypeInfo',
  'dayChange',
  'valueHistory',
  'maxPrecision',
  'delegationAddresses',
  'addressValidationRegex',
  'iconURL',
  'blockchainName',
  'supportsMultipleAddresses',
  'networkInfo',
  'minReserveRequirement',
  'tokenType',
  'isFungible',
  'requiresMemo',
  'title',
  'isFiat',
];

function handlePreProcessing(code: string): string {
  return code.replace(/fill=".*?"/g, 'fill="currentColor"');
}

/**
 *
 * @deprecated
 * use "@latitude/asset-icon" instead
 * */
const AssetIcon = ({
  name,
  iconURL,
  isFungible = true,
  ...restProps
}: Props): CommonComponentReturn => {
  const props = {
    height: 24,
    title: name,
    width: 24,
    ..._omit(restProps, ASSET_TYPE_INFO_FIELDS),
  } as SVGProps & { title: string };

  if (process.env.NODE_ENV?.toLowerCase() === 'test') {
    return <FallbackIcon isFungible={isFungible} {...props} />;
  }

  return (
    <SVG
      cacheRequests
      loader={<Loader delay svgProps={props} />}
      preProcessor={handlePreProcessing}
      {...(props as InlineSVGProps)}
      src={iconURL}
    >
      <FallbackIcon isFungible={isFungible} {...props} />
    </SVG>
  );
};

export default AssetIcon;
