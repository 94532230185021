import { UIDeveloperError, handleError } from '@anchorage/sentry';

const formatValueForDisplay = (value: string): string => {
  if (!value || Number.isNaN(Number(value))) {
    return '';
  }

  const isNegative = value.startsWith('-');
  // Must turn this value absolute because of '-0'
  const absValue = value.replace('-', '');
  const [main, dec] = absValue.split('.');

  try {
    // BigInt is used to be able to handle big values
    // Incorrect type for BigInt.toLocaleString
    const formattedValue = BigInt(main).toLocaleString('en-US');

    const hasDecimal = dec !== undefined;

    return `${isNegative ? '-' : ''}${formattedValue}${
      hasDecimal ? `.${dec}` : ''
    }`;
  } catch (error: any) {
    handleError(
      new UIDeveloperError(
        `Error parsing ${main} to BigInt type: ${error?.message}`,
      ),
    );

    return '';
  }
};

export default formatValueForDisplay;
