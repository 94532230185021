import cn from 'classnames';

const UploadIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Upload', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29289 0.292893C9.68342 -0.0976311 10.3166 -0.0976311 10.7071 0.292893L15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711C15.3166 7.09763 14.6834 7.09763 14.2929 6.70711L11 3.41421V13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13V3.41421L5.70711 6.70711C5.31658 7.09763 4.68342 7.09763 4.29289 6.70711C3.90237 6.31658 3.90237 5.68342 4.29289 5.29289L9.29289 0.292893ZM1 12C1.55229 12 2 12.4477 2 13V14.2C2 15.0566 2.00078 15.6389 2.03755 16.089C2.07337 16.5274 2.1383 16.7516 2.21799 16.908C2.40973 17.2843 2.7157 17.5903 3.09202 17.782C3.24842 17.8617 3.47262 17.9266 3.91104 17.9624C4.36113 17.9992 4.94342 18 5.8 18H14.2C15.0566 18 15.6389 17.9992 16.089 17.9624C16.5274 17.9266 16.7516 17.8617 16.908 17.782C17.2843 17.5903 17.5903 17.2843 17.782 16.908C17.8617 16.7516 17.9266 16.5274 17.9624 16.089C17.9992 15.6389 18 15.0566 18 14.2V13C18 12.4477 18.4477 12 19 12C19.5523 12 20 12.4477 20 13V14.2413C20 15.0463 20 15.7106 19.9558 16.2518C19.9099 16.8139 19.8113 17.3306 19.564 17.816C19.1805 18.5686 18.5686 19.1805 17.816 19.564C17.3306 19.8113 16.8139 19.9099 16.2518 19.9558C15.7106 20 15.0463 20 14.2413 20H5.75868C4.95372 20 4.28936 20 3.74817 19.9558C3.18608 19.9099 2.66937 19.8113 2.18404 19.564C1.43139 19.1805 0.819468 18.5686 0.435975 17.816C0.188684 17.3306 0.0901197 16.8139 0.0441945 16.2518C-2.28137e-05 15.7106 -1.23241e-05 15.0463 4.31292e-07 14.2413L9.08129e-07 13C9.08129e-07 12.4477 0.447716 12 1 12Z"
      />
    </svg>
  );
};
export { UploadIcon };
