import React from 'react';

import { SVGProps } from './types';

export default function AssetLoaderIcon({
  ...restProps
}: SVGProps): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...restProps}
    >
      <path
        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        style={{ stroke: 'var(--colorCompSpinnerPrimaryContainer)' }}
        strokeWidth="2"
        fill="none"
      />
      <mask
        id="mask0_10336_1773"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="0"
        width="12"
        height="12"
      >
        <rect
          x="12"
          width="12"
          height="12"
          style={{ fill: 'var(--colorCompSpinnerPrimaryOnContainer)' }}
        />
      </mask>
      <g
        mask="url(#mask0_10336_1773)"
        className="animate-spin origin-center"
        fill="none"
      >
        <circle
          cx="12"
          cy="12"
          r="11"
          style={{ stroke: 'var(--colorCompSpinnerPrimaryOnContainer)' }}
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
