import { Slot } from '@radix-ui/react-slot';
import { VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import { PropsWithChildren, forwardRef } from 'react';

import { textVariants } from './variants/text-variants';

type TextProps = VariantProps<typeof textVariants> &
  PropsWithChildren & {
    /**
     * Whether the button is used as a child component within a Slot.
     * @default false
     */
    asChild?: boolean;
    /**
     * Custom class name to apply to the Text component.
     */
    className?: string;
    'data-testid'?: string;
    title?: string;
  };

const Text = forwardRef<HTMLDivElement, TextProps>(function Text(
  {
    variant = 'bodyRegular',
    className = '',
    asChild = false,
    children = undefined,
    'data-testid': dataTestId = 'typography',
    title,
  },
  ref,
) {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      ref={ref}
      title={title}
      data-testid={dataTestId}
      className={cn([className, textVariants({ variant })])}
    >
      {children}
    </Comp>
  );
});

export { Text, type TextProps };
